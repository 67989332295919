import { useRef, useEffect, useContext, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import cartImg from "../../../assets/store/bag.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { DisplayContext } from "../../../AppContexts/DisplayContext";
import { clearAlphaSearchState } from "../../../Actions/AlphaSearchResult";
import { AiFillCaretDown } from "react-icons/ai";
import { AppContext } from "../../../AppContexts/AppContext";
import { logout } from "../../../Actions/Auth/authSlice";
import { useCartItemsQuery } from "../../../Actions/Cart/Cart";
import "../styles/home.css"


const HomeNavbar = () => {
     const {setProductId,handleMenuSwitch,handleDeliveryCheckout,handleMobileMenuSwitch,handleMobileDeliveryCheckout} = useContext(DisplayContext);
     const [profileModal, setProfileModal] = useState(false);
     const {setCurrentUrl} = useContext(AppContext)
     const {first_name, token, has_store} = useSelector(state => state.auth);
     const {cartId} = useSelector(state => state.cartId);
     const {data} = useCartItemsQuery(cartId);
     const cartLength = data && data?.results?.cart?.items.length  
     const navRef = useRef(null);
     const headerRef = useRef(null);
     const location = useLocation();
     const navigate = useNavigate();
     const dispatch = useDispatch()
     const showNavbar = () => {
        navRef.current?.classList?.toggle("responsive_nav");
        setProfileModal(false)
    }
    const closeModals = () => {
      handleMenuSwitch(0);
      handleDeliveryCheckout(0);
      handleMobileMenuSwitch(0);
      handleMobileDeliveryCheckout(0);
    }
    const navigateToHome = () => {
      dispatch(clearAlphaSearchState())       
       navigate("/",{ replace: true });
       setProductId("")
 }

    const activeLink = (link) => {
         return location.pathname === link && link !== "/cart" ? "active-link" : ""
    }
    useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window?.scrollY > 150 && headerRef && headerRef.current) {
              headerRef.current?.classList?.add('scrolled');
            } else {
              headerRef.current?.classList?.remove('scrolled');
            }
          })
    },[headerRef]);

    return (

         <>
               {
                    !location.pathname.startsWith("/store") &&
                  <div className="navigation-bar sticky-top" ref={headerRef}>
             
              
                    <div className="navigation-items">
                        <div className="logo" onClick={navigateToHome}>
                          <img src={ window.innerWidth < 768 ? "/alpha.svg" :"/logo-alpha.png"} alt="gig-logo"/>
                        </div>
                        
                            <div className="home-navbar" ref={navRef}>
                                <NavLink to="/about" className={() => activeLink("/about")} onClick={showNavbar}>About Alpha</NavLink>
                                <NavLink to="/faq" className={() => activeLink("/faq")} onClick={showNavbar}>FAQ</NavLink>
                                <NavLink to="/pricing" className={() => activeLink("/pricing")} onClick={showNavbar}>Pricing</NavLink>
                                <NavLink to="/contact" className={() => activeLink("/contact")} onClick={showNavbar}>Contact us</NavLink>
                                {/* <NavLink to="/payment/refund" className={() => activeLink("/contact")} onClick={showNavbar}>Return/Refund</NavLink> */}
                                <div className="my-profile" onClick={() => setProfileModal(prev => !prev)}><AiFillCaretDown className={profileModal ? "caret rotate-caret" : "caret"}/>{" "}<span>{first_name ? `Hi, ${first_name}` : "My Profile"}</span></div>
                                <NavLink to="/shop" className={() => activeLink("/shop")} onClick={showNavbar}><button className="shop-btn">Shop Now</button></NavLink>
                                {
                                   profileModal && <div className="user-modal">
                                    {
                                      token ? <button onClick={() => {dispatch(logout()); showNavbar()}}>Log out</button> : <Link to="/login" id="signin-btn" onClick={() => {showNavbar(); setCurrentUrl(location.pathname)}}>Sign In</Link>
                                    }
                                    <div className="modal-links">
                                       <div className="hr"></div>
                                       <div className="account-links-mobile">
                                          <Link to="/user/mobile" onClick={() => {showNavbar(); setCurrentUrl(location.pathname)}}>My Profile</Link>
                                       </div>
                                       <div className="account-links">
                                          <Link to="/user/account" onClick={() => {showNavbar(); setCurrentUrl(location.pathname)}}>My account</Link>
                                          <Link to="/user/orders" onClick={() => {showNavbar(); setCurrentUrl(location.pathname)}}>My orders</Link>
                                       </div>
                                       {
                                           has_store && 
                                           <>
                                              <div className="hr"></div>
                                              <Link to="/merchant/store" onClick={showNavbar}>Go to store</Link>
                                           </>
                                       }
                                    
                                    </div>
                                    
                                     
                                   </div>
                                }
                                <div className="mobile-hide-icons">
                                <NavLink to="/cart" onClick={() => {showNavbar(); closeModals()}} className={() => {activeLink("/cart");}}>
                                  <div className="basket-badge"><img src={cartImg} alt=""/>{cartLength > 0 && <div className="item-count">{cartLength}</div>}</div></NavLink>
                                <div className="nav-btn nav-close-btn" onClick={showNavbar}>
                                    <FaTimes />
                                </div> 
                                </div>
                            </div>
                            <div className="cart-mobile-icons">
                              <NavLink to="/cart" onClick={closeModals} className={() => activeLink("/cart")}>
                                    <div className="basket-badge"><img src={cartImg} alt=""/>{cartLength > 0 && <div className="item-count">{cartLength}</div>}</div></NavLink>
                              <div className="nav-btn" onClick={showNavbar}>
                                <FaBars />
                              </div>
                            </div>                            
                    </div>
                </div>
              }
         </>
        
    )
}

export default HomeNavbar